<template>
  <section class="dtc-main-section mt-2 mr-2">
    <section class="search-block">
      <h5 class="big-title">
        原住民籍公費生申請分發至簽約時戶籍所在地縣市非原住民族地區衛生所缺額調查表(牙醫學系除外)
      </h5>

      <div class="dtc-search1">
        <b-input-group prepend="縣市別">
          <treeselect
            style="width: 260px;color:#333"
            v-model="searchCounty"
            :options="systemCountryObject"
            placeholder="搜尋縣縣市別"
            noResultsText="查無此縣市"
          />
        </b-input-group>

        <b-button variant="primary" @click="getData">進行查詢</b-button>
        <b-button variant="danger" @click="clearSearch">清除條件</b-button>
      </div>
    </section>
    <header class="dtc-grid-header ml-1 my-dark">
      <div>操作</div>
      <div>異動時間</div>

      <div>縣市別</div>
      <div>衛生所名稱</div>
      <!-- <div>地區別</div> -->
      <div class="header-column4">
        <div class="header-column4-row1">
          <!-- <div class="header-column4-row1-word">西醫師</div> -->
          <b-select
            style="width:160px"
            :options="[
              '西醫師',
              '藥師(藥劑生)',
              '護理師(護士)',
              '醫事檢驗師(生)',
              '醫事放射師(生)',
              '物理治療師(生)',
              '營養師',
              '其他醫事別',
            ]"
            class="mt-2"
            v-model="selectedType"
            @change="changeType"
          ></b-select>
        </div>
        <div class="header-column4-row2">
          <div>編制員額</div>
          <div>已進用員額數</div>
          <div>職缺數</div>
          <div>預計進用日</div>
          <div>約聘數</div>
          <div>約聘進用數</div>
          <div>約聘缺額數</div>
        </div>
      </div>
      <div>聯絡人及電話</div>
      <div>最後異動日期</div>
      <div>備註</div>
    </header>

    <!-- <main v-if="!items.length" class="dtc-grid-header main-dtc-body">
      <div
        style="grid-column: 1 / -1;padding: 20px 0px;font-size:24px;color:#000;"
      >
        暫無資料
      </div>
    </main> -->

    <table
      class="ml-1"
      v-for="(countryItem, countryIndex) in items"
      :key="`outer${countryIndex}`"
    >
      <tr v-for="(item, i) in countryItem.array" :key="`insider${i}`">
        <td style="width:90px" class="table-content">
          <b-button
            variant="success"
            class="mr-2"
            size="sm"
            @click="openModal(item)"
            >編輯</b-button
          >
        </td>
        <td style="width:160px">
          {{ item.LastUpdate ? $twDate5(item.LastUpdate) : "" }}
        </td>
        <td rowspan="0" style="width:110px" v-if="i == 0">
          {{ item.CountryName || "" }}
        </td>
        <td style="width:200px">
          {{ item.HealthName || "" }}
        </td>
        <!-- <td style="width:90px">
          {{ item.Zone || "" }}
        </td> -->
        <td style="width:120px">
          {{ item.MajorpreparedShow || "" }}
        </td>
        <td style="width:120px">
          {{ item.MajorHaveShow || "" }}
        </td>
        <td style="width:120px">
          {{ item.MajorNeedShow || "" }}
        </td>

        <td style="width:120px">
          {{ item.MajorInDateShow ? $twDate2(item.MajorInDateShow) : "" }}
        </td>
        <td style="width:120px">
          {{ item.MajorContractShow || "" }}
        </td>
        <td style="width:120px">
          {{ item.MajorContract2Show || "" }}
        </td>
        <td style="width:120px">
          {{ item.MajorContract3Show || "" }}
        </td>
        <td style="width:160px" :title="item.Contact_Info">
          {{ item.Contact_Info || "" }}
        </td>
        <td style="width:160px">
          {{ item.LastUpdate ? $twDate5(item.LastUpdate) : "" }}
        </td>
        <td style="width:260px" :title="item.Remark">
          {{ item.Remark || "" }}
        </td>
      </tr>
    </table>

    <footer class="dtx-footer">
      <b-pagination
        v-model="currentPageNum"
        :total-rows="rowCount"
        :per-page="pagingRowPerPage"
        aria-controls="dataTable1"
        align="center"
        class="mt-2"
      >
        <span slot="first-text">
          <i class="fas fa-fast-backward"></i>
        </span>
        <span slot="prev-text">
          <i class="fas fa-step-backward"></i>
        </span>
        <span slot="next-text">
          <i class="fas fa-step-forward"></i>
        </span>
        <span slot="last-text">
          <i class="fas fa-fast-forward"></i>
        </span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
      <b-select
        :options="rows"
        class="mt-2"
        v-model="pagingRowPerPage"
      ></b-select>
      <div class="mt-3">{{ totalCountStr }}</div>
    </footer>
    <div>
      <b-modal id="editModal" :title="`資料編輯: ${selectedType}`" size="lg">
        <div class="modal-grid-2">
          <b-input-group prepend="衛生所名稱" class="mt-2">
            <b-input
              v-model="modalItem.HealthName"
              style="padding-right:28px"
              disabled
            ></b-input>
          </b-input-group>
          <b-input-group prepend="編制員額" class="mt-2">
            <b-input
              v-model="modalItem.MajorpreparedShow"
              style="padding-right:28px"
              type="number"
              min="0"
              :disabled="isRoleHealthCenter"
            ></b-input>
          </b-input-group>
          <b-input-group prepend="已進用員額數" class="mt-2">
            <b-input
              v-model="modalItem.MajorHaveShow"
              style="padding-right:28px"
              type="number"
              min="0"
              :disabled="isRoleHealthCenter"
            ></b-input>
          </b-input-group>
          <b-input-group prepend="職缺數" class="mt-2">
            <b-input
              v-model="modalItem.MajorNeedShow"
              style="padding-right:28px"
              type="number"
              min="0"
              disabled
            ></b-input>
          </b-input-group>
          <b-input-group prepend="預計進用日" class="mt-2">
            <date-picker-tw
              :time="modalItem.MajorInDateShow"
              @update="(t) => (modalItem.MajorInDateShow = t)"
              :class="isRoleHealthBureauCheck ? 'avoid-clicks' : ''"
            ></date-picker-tw>
          </b-input-group>
          <b-input-group prepend="約聘數" class="mt-2">
            <b-input
              v-model="modalItem.MajorContractShow"
              style="padding-right:28px"
              type="number"
              min="0"
              :disabled="isRoleHealthBureauCheck"
            ></b-input>
          </b-input-group>
          <b-input-group prepend="約聘進用數" class="mt-2">
            <b-input
              v-model="modalItem.MajorContract2Show"
              style="padding-right:28px"
              type="number"
              min="0"
              :disabled="isRoleHealthBureauCheck"
            ></b-input>
          </b-input-group>
          <b-input-group prepend="約聘缺額數" class="mt-2">
            <b-input
              v-model="modalItem.MajorContract3Show"
              style="padding-right:28px"
              type="number"
              min="0"
              disabled
            ></b-input>
          </b-input-group>
          <b-input-group prepend="聯絡人及電話" class="mt-2">
            <b-input
              v-model="modalItem.Contact_Info"
              style="padding-right:28px"
            ></b-input>
          </b-input-group>
          <b-input-group prepend="備註" class="mt-2">
            <b-input
              v-model="modalItem.Remark"
              style="padding-right:28px"
            ></b-input>
          </b-input-group>
        </div>
        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button
              variant="danger"
              class="float-right mr-1"
              @click="$bvModal.hide('editModal')"
              >取消</b-button
            >
            <b-button
              variant="primary"
              class="float-right mr-1"
              @click="saveModal"
              >儲存</b-button
            >
          </div>
        </template>
      </b-modal>
    </div>
  </section>
</template>

<script>
import { store } from "@/store/global.js";
import buildQuery from "odata-query";

import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
const year = new Date().getFullYear() - 1911;
const rows = [10, 20, 50];

const allType = [
  {
    name: "西醫師",
    MajorpreparedShow: "Majorprepared_1",
    MajorHaveShow: "MajorHave_1",
    MajorNeedShow: "MajorNeed_1",
    MajorInDateShow: "MajorInDate_1",
    MajorContractShow: "MajorContract_1",
    MajorContract2Show: "MajorContract_11",
    MajorContract3Show: "MajorContract_12",
  },
  {
    name: "牙醫師",
    MajorpreparedShow: "Majorprepared_2",
    MajorHaveShow: "MajorHave_2",
    MajorNeedShow: "MajorNeed_2",
    MajorInDateShow: "MajorInDate_2",
    MajorContractShow: "MajorContract_2",
    MajorContract2Show: "MajorContract_21",
    MajorContract3Show: "MajorContract_22",
  },
  {
    name: "藥師(藥劑生)",
    MajorpreparedShow: "Majorprepared_3",
    MajorHaveShow: "MajorHave_3",
    MajorNeedShow: "MajorNeed_3",
    MajorInDateShow: "MajorInDate_3",
    MajorContractShow: "MajorContract_3",
    MajorContract2Show: "MajorContract_31",
    MajorContract3Show: "MajorContract_32",
  },
  {
    name: "護理師(護士)",
    MajorpreparedShow: "Majorprepared_4",
    MajorHaveShow: "MajorHave_4",
    MajorNeedShow: "MajorNeed_4",
    MajorInDateShow: "MajorInDate_4",
    MajorContractShow: "MajorContract_4",
    MajorContract2Show: "MajorContract_41",
    MajorContract3Show: "MajorContract_42",
  },
  {
    name: "醫事檢驗師(生)",
    MajorpreparedShow: "Majorprepared_5",
    MajorHaveShow: "MajorHave_5",
    MajorNeedShow: "MajorNeed_5",
    MajorInDateShow: "MajorInDate_5",
    MajorContractShow: "MajorContract_5",
    MajorContract2Show: "MajorContract_51",
    MajorContract3Show: "MajorContract_52",
  },
  {
    name: "醫事放射師(生)",
    MajorpreparedShow: "Majorprepared_6",
    MajorHaveShow: "MajorHave_6",
    MajorNeedShow: "MajorNeed_6",
    MajorInDateShow: "MajorInDate_6",
    MajorContractShow: "MajorContract_6",
    MajorContract2Show: "MajorContract_61",
    MajorContract3Show: "MajorContract_62",
  },
  {
    name: "物理治療師(生)",
    MajorpreparedShow: "Majorprepared_7",
    MajorHaveShow: "MajorHave_7",
    MajorNeedShow: "MajorNeed_7",
    MajorInDateShow: "MajorInDate_7",
    MajorContractShow: "MajorContract_7",
    MajorContract2Show: "MajorContract_71",
    MajorContract3Show: "MajorContract_72",
  },
  {
    name: "營養師",
    MajorpreparedShow: "Majorprepared_8",
    MajorHaveShow: "MajorHave_8",
    MajorNeedShow: "MajorNeed_8",
    MajorInDateShow: "MajorInDate_8",
    MajorContractShow: "MajorContract_8",
    MajorContract2Show: "MajorContract_81",
    MajorContract3Show: "MajorContract_82",
  },
  {
    name: "其他醫事別",
    MajorpreparedShow: "Majorprepared_9",
    MajorHaveShow: "MajorHave_9",
    MajorNeedShow: "MajorNeed_9",
    MajorInDateShow: "MajorInDate_9",
    MajorContractShow: "MajorContract_9",
    MajorContract2Show: "MajorContract_91",
    MajorContract3Show: "MajorContract_92",
  },
];

export default {
  name: "HcQuestionnaire",
  props: ["tabName"],
  data() {
    return {
      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 10,
      orderBy: [],
      rows,
      totalCountStr: "",

      currentTime: new Date(),

      headers: [],
      variants: [
        "warning",
        "dark",
        "light",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],

      //for search
      searchCounty: null,
      systemCountryObject: [],

      items: [],
      countryAllExist: [],
      selectedType: "西醫師",
      allType,

      //for changeType need
      itemsForChange: [],
      allHealthBureauOption: [],

      modalItem: {},
    };
  },
  components: { Treeselect },
  props: [],
  computed: {
    isRoleHealthBureauCheck() {
      const loginHealthCode = sessionStorage
        .getItem("darcok")
        .replace(/\"/g, "");
      const arr = JSON.parse(sessionStorage.getItem("roleHealthBureau"));
      return arr.includes(loginHealthCode);
    },
    isRoleHealthCenter() {
      const loginHealthCode = sessionStorage
        .getItem("darcok")
        .replace(/\"/g, "");
      const arr = JSON.parse(sessionStorage.getItem("roleHealthCenter"));
      return arr.includes(loginHealthCode);
    },
  },
  methods: {
    changeType() {
      let arr = JSON.parse(JSON.stringify(this.itemsForChange));

      let keyObj = this.allType.find((s) => s.name == this.selectedType);

      arr = arr.map((s) => {
        s.MajorpreparedShow = s[`${keyObj.MajorpreparedShow}`];
        s.MajorHaveShow = s[`${keyObj.MajorHaveShow}`];
        s.MajorNeedShow = s[`${keyObj.MajorNeedShow}`];
        s.MajorInDateShow = s[`${keyObj.MajorInDateShow}`];
        s.MajorContractShow = s[`${keyObj.MajorContractShow}`];
        s.MajorContract2Show = s[`${keyObj.MajorContract2Show}`];
        s.MajorContract3Show = s[`${keyObj.MajorContract3Show}`];
        return s;
      });

      let countryObj = JSON.parse(JSON.stringify(this.countryAllExist));

      countryObj = countryObj.map((s, i) => ({
        index: i + 1,
        array: arr.filter((k) => k.CountryName == s),
      }));

      this.items = JSON.parse(JSON.stringify(countryObj));
    },
    async openModal(item) {
      this.modalItem = JSON.parse(JSON.stringify(item));
      this.modalItem.MajorInDateShow = this.$twDate2(item.MajorInDateShow);

      this.$bvModal.show("editModal");
    },
    async saveModal() {
      const url = `api/Questionnaire`;
      const obj = JSON.parse(JSON.stringify(this.modalItem));

      let keyObj = this.allType.find((s) => s.name == this.selectedType);

      obj[`${keyObj.MajorpreparedShow}`] = obj.MajorpreparedShow;
      obj[`${keyObj.MajorHaveShow}`] = obj.MajorHaveShow;
      obj[`${keyObj.MajorNeedShow}`] = obj.MajorNeedShow;
      obj[`${keyObj.MajorInDateShow}`] = obj.MajorInDateShow
        ? this.$usDate(obj.MajorInDateShow)
        : null;
      obj[`${keyObj.MajorContractShow}`] = obj.MajorContractShow;
      obj[`${keyObj.MajorContract2Show}`] = obj.MajorContract2Show;
      obj[`${keyObj.MajorContract3Show}`] = obj.MajorContract3Show;
      obj.LastUpdate = this.$moment(new Date()).format("YYYY-MM-DDTHH:mm:ss");
      try {
        await window.axios.put(url, obj);
        this.$bvToast.toast(`編輯成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
        this.getData();

        this.$bvModal.hide("editModal");
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    clearSearch() {
      this.searchCounty = null;
      this.getData();
    },
    sort(item) {
      if (!item.key) {
        return;
      }
      if (item.sortDesc) {
        item.sortDesc = null;
      } else if (false === item.sortDesc) {
        item.sortDesc = true;
      } else if (null === item.sortDesc) {
        item.sortDesc = false;
      }
      this.orderBy = [];

      this.headersOne.forEach((s) => {
        if (s.sortDesc !== null) {
          this.orderBy.push(s.sortDesc ? `${s.key} desc` : `${s.key}`);
        }
      });

      this.getData();
    },
    async getData() {
      let healthCode = "";

      if (this.isRoleHealthCenter) {
        healthCode = sessionStorage.getItem("darcok").replace(/\"/g, "");
      }

      if (this.isRoleHealthBureauCheck) {
        const centerCode = sessionStorage.getItem("darcok").replace(/\"/g, "");
        const centerPlace = this.allHealthBureauOption.find(
          (s) => s.No == centerCode
        ).Name;
        let placeChinese = centerPlace.substring(0, 3);
        this.systemCountryObject = this.systemCountryObject.filter(
          (s) => s.label == placeChinese
        );
        this.searchCounty = this.systemCountryObject[0]
          ? this.systemCountryObject[0].id
          : "123456789";
      }
      //odata3 qs
      const page = this.currentPageNum;
      const skip = (page - 1) * this.pagingRowPerPage;
      const top = this.pagingRowPerPage;
      const orderBy = this.orderBy;
      const obj = { top, skip, orderBy };
      let qs = buildQuery(obj);

      qs = this.$equalSubstringofFilter("Category", qs, 2);

      if (this.searchCounty) {
        qs = this.$appendEqualFilter("CountryCode", qs, this.searchCounty);
      }
      if (healthCode) {
        qs = this.$appendEqualFilter("HealthCode", qs, healthCode);
      }
      try {
        let { Items, Count } = await window.axios.get(`api/Questionnaire${qs}`);
        this.itemsForChange = JSON.parse(JSON.stringify(Items));

        let keyObj = this.allType.find((s) => s.name == this.selectedType);

        Items = Items.map((s) => {
          s.MajorpreparedShow = s[`${keyObj.MajorpreparedShow}`];
          s.MajorHaveShow = s[`${keyObj.MajorHaveShow}`];
          s.MajorNeedShow = s[`${keyObj.MajorNeedShow}`];
          s.MajorInDateShow = s[`${keyObj.MajorInDateShow}`];
          s.MajorContractShow = s[`${keyObj.MajorContractShow}`];
          s.MajorContract2Show = s[`${keyObj.MajorContract2Show}`];
          s.MajorContract3Show = s[`${keyObj.MajorContract3Show}`];
          return s;
        });

        let countryObj = JSON.parse(JSON.stringify(this.countryAllExist));

        countryObj = countryObj.map((s, i) => ({
          index: i + 1,
          array: Items.filter((k) => k.CountryName == s),
        }));

        this.items = JSON.parse(JSON.stringify(countryObj));

        this.rowCount = Count;
        this.totalCountStr = `共${Count} 筆`;
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getAllExistCountry() {
      let { Items } = await window.axios.get(
        `api/Questionnaire?$filter=substringof('2' , Category )`
      );
      //for rebuild data
      let allArr = JSON.parse(JSON.stringify(Items));
      allArr = allArr.map((s) => s.CountryName);
      this.countryAllExist = [...new Set(allArr)];

      //for search
      let allArr2 = JSON.parse(JSON.stringify(Items));

      allArr2 = allArr2.map((s) => ({
        id: s.CountryCode,
        label: `${s.CountryName}`,
      }));
      allArr2 = this.$dedup(allArr2);
      this.systemCountryObject = JSON.parse(JSON.stringify(allArr2));
    },
    async getAllHealthBureau() {
      const arr1 = await window.axios.get(
        "SysRole/GetRoleInstitutions?no=user1"
      );
      this.allHealthBureauOption = [...arr1];
    },
  },
  async mounted() {
    await this.getAllHealthBureau();
    await this.getAllExistCountry();
    await this.getData();
  },
  watch: {
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
    "modalItem.MajorpreparedShow"(v) {
      this.modalItem.MajorNeedShow =
        +this.modalItem.MajorpreparedShow - +this.modalItem.MajorHaveShow > 0
          ? +this.modalItem.MajorpreparedShow - +this.modalItem.MajorHaveShow
          : 0;
    },
    "modalItem.MajorHaveShow"(v) {
      this.modalItem.MajorNeedShow =
        +this.modalItem.MajorpreparedShow - +this.modalItem.MajorHaveShow > 0
          ? +this.modalItem.MajorpreparedShow - +this.modalItem.MajorHaveShow
          : 0;
    },
    "modalItem.MajorContractShow"(v) {
      this.modalItem.MajorContract3Show =
        +this.modalItem.MajorContractShow - +this.modalItem.MajorContract2Show >
        0
          ? +this.modalItem.MajorContractShow -
            +this.modalItem.MajorContract2Show
          : 0;
    },
    "modalItem.MajorContract2Show"(v) {
      this.modalItem.MajorContract3Show =
        +this.modalItem.MajorContractShow - +this.modalItem.MajorContract2Show >
        0
          ? +this.modalItem.MajorContractShow -
            +this.modalItem.MajorContract2Show
          : 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.dtc-main-section {
  padding: 12px;
  padding-left: 3px;
  width: fit-content;
}
.dtc-grid-header {
  display: grid;
  grid-template-columns: 90px 160px 110px 200px 840px 160px 160px 260px;
  grid-auto-flow: column;
  text-align: center;
  padding-right: 0px;

  border: 1px solid #dee2e5;
  border-bottom: 0px;
  margin-right: 4px;
  color: #0757a7;
  > div {
    border-right: 1px solid #dee2e5;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 16px;
    padding: 4px 0;
    height: 80px;
    padding-top: 25px;
  }
  > div:last-child {
    border-right: none;
  }
  .header-column4 {
    padding-top: 0px;

    .header-column4-row1 {
      text-align: center;
      height: 55px;
      line-height: 46px;
    }
    .header-column4-row2 {
      display: grid;
      grid-template-columns: repeat(7, 120px);
      > div {
        border-right: 1px solid #dee2e5;
        border-top: 1px solid #dee2e5;
        height: 25px;
      }
    }
  }
}
.main-dtc-body {
  &:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }
  > div {
    line-height: 24px;
  }
}
.dtc-grid-main,
.dtc-grid-footer,
.dtc-grid-footer2 {
  display: grid;
  grid-template-columns: 490px 90px 200px 90px 630px 160px 160px 260px;
  margin-right: 4px;
  grid-auto-flow: column;
  text-align: center;
  padding-right: 0px;
  border-left: 1px solid #dee2e5;
  border-right: 1px solid #dee2e5;
  border-top: 1px solid #dee2e5;
  color: #212529;
  > div {
    border-right: 1px solid #dee2e5;
    border-bottom: 1px solid #dee2e5;
    color: #212529;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 16px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  > div:last-child {
    border-right: none;
  }
}
.dtc-grid-footer {
  grid-template-columns: 100px 60px repeat(10, 120px) 180px;
  border: 1px solid #dee2e5;
  color: #212529;

  > div {
    border-right: 1px solid #dee2e5;
    margin-top: 0px;
  }
  > div:last-child {
    border-right: none;
  }

  .column4-content-out {
    .content-row {
      > div {
        border-right: 1px solid #dee2e5;
        border-top: 1px solid #dee2e5;
      }
      > div:last-child {
        border-right: none;
      }
    }
  }
  .column5-content-out {
    .content-row {
      > div {
        border-right: 1px solid #dee2e5;
        border-top: 1px solid #dee2e5;
      }
      > div:last-child {
        border-right: none;
      }
    }
  }
}
.dtc-grid-footer2 {
  grid-template-columns: 100px 1600px;
}

.my-dark {
  > div {
    // background: var(--gray);
    background: #333;
    color: white;
  }
}

::v-deep.btn-light {
  background: #7e2ca3;
  border-color: #7e2ca3;
  color: #fff;
}
.search-block {
  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 2px 2px 0 0;
  width: 99.3%;
  margin: 0 auto 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 2px 2px 0 0;
  }
  .dtc-search,
  .dtc-search1 {
    padding-left: 10px;
    display: grid;
    max-height: 40px;
    margin-bottom: 12px;
    grid-template-columns: repeat(30, max-content);
    grid-gap: 12px;
    > * {
      max-height: 44px;
    }
  }
}
.input-group-text {
  background: #0379fd;
  color: #fff;
  min-width: 130px;
}
td {
  border: 1px solid #dee2e5;
  text-align: center;
}
.table-content {
  padding: 3px;
}
.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}
::v-deep .modal-header {
  background: #7e2ca3;
  color: #ffffff;
}
</style>
